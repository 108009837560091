import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';

const useScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (history.length > 1) {
      window.scrollTo(0, 0);
    }
  }, [history.length, location.pathname]);

  return <>{children}</>;
};

export default useScrollToTop;

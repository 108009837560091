import React from 'react';
import { createUseStyles } from 'react-jss';
import cardBg from '../../assets/img/backcard.png';

const useStyles = createUseStyles({
  selected: {
    transform: 'scale(1.3)',
    boxShadow: '9px 13px 9px 0px rgba(0, 0, 0, 0.53)',
    border: '1px solid rgba(0, 0, 0, 0.53)',
  },
});

const Card: React.FC<{ isSelected: boolean; onCardClick: () => void }> = ({
  isSelected,
  onCardClick,
}) => {
  const classes = useStyles();

  return (
    <img
      src={cardBg}
      className={`card ${isSelected ? classes.selected : ''}`}
      onClick={() => onCardClick()}
      alt="Carta"
    />
  );
};

export default Card;

import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { CardModel } from '../../models/CardModel';

const useStyles = createUseStyles({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

const Game: React.FC<{ maxSelection: number; cards: CardModel[] }> = ({ maxSelection, cards }) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedCards, setSelectedCards] = React.useState<string[]>([]);
  const handleCardClick = (card: CardModel) => {
    if (selectedCards.filter((x) => x === card.id).length > 0) {
      setSelectedCards(selectedCards.filter((x) => x !== card.id));
    } else {
      if (maxSelection > selectedCards.length) {
        setSelectedCards([...selectedCards, card.id]);
      }
    }
  };
  const handleReveal = () => {
    localStorage.setItem('selectedCards', selectedCards.toString());
    history.push(`/reveal`);
  };

  return (
    <div className="cartas">
      <div className={classes.flex}>
        {cards.map((card) => {
          return (
            <Card
              onCardClick={() => handleCardClick(card)}
              isSelected={selectedCards.filter((x) => x === card.id).length > 0}
              key={`card-${card.id}`}
            />
          );
        })}
      </div>
      <div className="cta">
        {maxSelection > selectedCards.length ? (
          <>Seleccionar {maxSelection - selectedCards.length} más</>
        ) : (
          <>
            <button onClick={handleReveal} className="button">
              Revelar
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Game;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardModel } from '../../models/CardModel';

const Result: React.FC<{ card: CardModel; time: 'Pasado' | 'Presente' | 'Futuro' | 'Other' }> = ({
  card,
  time,
}) => {
  return (
    <div className="flex-column result-card">
      <div className="flex">
        <img src={`/cartas/${card.id}.png`} alt={card.name} title={card.name} />
        <p>
          {time !== 'Other' && <h2>{time}</h2>}
          {card.description}
          {card.link !== '' && (
            <a href={card.link} className="see-product" target="_blank">
              Ver el producto (20cl)
            </a>
          )}
          {card.linkSmall !== '' && (
            <a href={card.linkSmall} className="see-product" target="_blank">
              Ver el producto (10cl)
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

const Reveal: React.FC<{ cards: CardModel[] }> = ({ cards }) => {
  const history = useHistory();
  const [isScrolled, setIsScrolled] = React.useState(false);
  const cardPickedLocalStorage = localStorage.getItem('selectedCards');
  const wordPickedLocalStorage = localStorage.getItem('selectedWord');
  if (!cardPickedLocalStorage || !wordPickedLocalStorage) {
    history.push('/');
  }
  const cardIdPicked = cardPickedLocalStorage?.split(',');
  const cardsPicked = cards.filter((x) => cardIdPicked!.includes(x.id));
  const wordPicked = wordPickedLocalStorage;

  const handleRestart = () => {
    localStorage.removeItem('selectedCards');
    localStorage.removeItem('selectedWord');
    history.push('/');
  };

  window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  });

  return (
    <div className="main reveal">
      <div className="backgound">
        <h1>Tu pregunta</h1>
        <p>{wordPicked}</p>
      </div>
      {cardsPicked.length > 1 ? (
        <div className="flex reveal-result">
          <Result card={cardsPicked[0]} time="Pasado" />
          <Result card={cardsPicked[1]} time="Presente" />
          <Result card={cardsPicked[2]} time="Futuro" />
        </div>
      ) : (
        <>
          <Result card={cardsPicked[0]} time="Other" />
        </>
      )}
      <div className={`cta ${isScrolled ? 'show' : ''}`}>
        <button onClick={handleRestart} className={`button`}>
          Jugar otra vez
        </button>
      </div>
    </div>
  );
};

export default Reveal;

import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import Home from './pages/Home/Home';
import Game from './pages/Game/Game';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { CardModel } from './models/CardModel';
import cardsData from './assets/data/cards.json';
import Reveal from './pages/Reveal/Reveal';

const Routes = () => {
  const shuffle = (arr: CardModel[]) => arr.sort(() => 0.5 - Math.random());

  return (
    <Switch>
      <Route
        path={'/'}
        exact={true}
        render={() => (
          <RenderPage>
            <Home />
          </RenderPage>
        )}
      />
      <Route
        path={'/game/:numberOfPick'}
        exact={true}
        render={({ match }: RouteComponentProps<{ numberOfPick: string }>) => {
          let maxPick = parseInt(match.params.numberOfPick);
          maxPick = maxPick !== 1 ? 3 : 1;
          return (
            <RenderPage>
              <Game maxSelection={maxPick} cards={shuffle(cardsData)} />
            </RenderPage>
          );
        }}
      />
      <Route
        path={'/reveal'}
        exact={true}
        render={() => (
          <RenderPage>
            <Reveal cards={cardsData} />
          </RenderPage>
        )}
      />
    </Switch>
  );
};

const RenderPage: React.FC = ({ children }) => {
  React.useEffect(() => {
    document.body.classList.remove('loading');
  }, []);

  return <ScrollToTop>{children}</ScrollToTop>;
};

export default Routes;

import React from 'react';
import { useHistory } from 'react-router-dom';

const Home = () => {
  const [word, setWord] = React.useState<string>('');
  const [numberOfCards, setNumberOfCards] = React.useState<number>(3);
  const history = useHistory();

  const handleConsultar = () => {
    if (word.length > 0) {
      localStorage.setItem('selectedWord', word);
      history.push(`/game/${numberOfCards}`);
    }
  };

  return (
    <div className="main flex home">
      <div className="whiteBg">
        <h1>Haz una pregunta a las cartas</h1>
        <input
          type="text"
          placeholder="Tu pregunta"
          onChange={(e) => {
            setWord(e.target.value);
          }}
        />
        <div className="mt-2">
          <div>
            <input
              type="radio"
              id="unaCarta"
              name="cuandoCartas"
              value="1"
              checked={numberOfCards === 1}
              onClick={() => setNumberOfCards(1)}
            />
            <label htmlFor="unaCarta">Una carta</label>
            <input
              type="radio"
              id="tresCartas"
              name="cuandoCartas"
              value="3"
              checked={numberOfCards === 3}
              onClick={() => setNumberOfCards(3)}
            />
            <label htmlFor="tresCartas">Tres cartas</label>
          </div>
        </div>
        <button onClick={handleConsultar} className="button mt-2">
          Consultar
        </button>
      </div>
    </div>
  );
};

export default Home;
